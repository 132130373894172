@use '../../../../assets/style' as *;

.contacts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 72px;
    max-width: 944px;
    width: 100%;
    text-align: center;
    gap: 40px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
            font-size: $font-lg;
            line-height: 28px;
        }
    }

    .button-group {
        display: flex;
        gap: 24px;

        a {
            background-color: $blue-GFDP;
            color: $text-white;
            height: 64px;
            width: 173px;
            border-radius: 48px;
            font-size: $font-sm;
            font-weight: $font-bold;
            line-height: 24px;
            gap: 8px;

            &:hover {
                background-color: $mid-blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }
}

@include tablet {
    .contacts-wrapper {
        padding: 40px;
    }
}

@include mobile {
    .contacts-wrapper {
        padding: 40px 16px;
        .header {
            p {
                font-size: $font-md;
                line-height: 24px;
            }
        }
    }
}

@include mobile_sm {
    .contacts-wrapper {
        .button-group {
            flex-direction: column;
            gap: 16px;
            width: 100%;

            a {
                width: 100%;
            }
        }
    }
}