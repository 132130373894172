@use '../../assets/style' as *;

.forgot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h5 {
        font-size: $font-xxl;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 630px;
        width: 100%;
        padding: 0 40px;
        gap: 16px;
        text-align: center;
        position: relative;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    #forgot-form {
        font-size: $font-sm;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .form-section {
        text-align: left;
    }

    button {
        width: 100%;
    }

    .onboard {
        flex-direction: column;
        align-items: center;

        p {
            font-size: $font-sm;
            line-height: 2;
            a {
                font-size: $font-sm;
                font-weight: $font-semibold;
            }
        }
    }

    .plink {
        display: flex;
        gap: 4px;
    }
}

.login {
    opacity: 0;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: $font-semibold;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: initial;
        transition: 500ms ease-in-out;
    }
}

.toast {
    position: fixed;
    bottom: 64px;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: $radius-general;
    padding: 10px 20px 10px 16px;
    width: max-content;
    transform: translateY(124px);
    text-align: left;

    p {
        font-size: $font-xs;
        color: $text-title;
        line-height: 18px;
    }

    &.success {
        background-color: #c7eccc;

        .toast-icon {
            path {
                fill: $dark-green-GFDP;
            }
        }

        .message {

            h6,
            p {
                color: $dark-green-GFDP
            }
        }
    }

    &.error {
        background-color: #fed2d2;

        .toast-icon {
            path {
                fill: #cb0606;
            }
        }

        .message {

            h6,
            p {
                color: #cb0606;
            }
        }
    }

    &.show {
        transform: translateY(0);
        transition: transform 300ms ease-in-out;
    }
}

@include mobile {
    .forgot {
        padding: 40px 16px;
        justify-content: space-between;

        .form-wrapper {
            padding: 0;
        }
    }

    .toast {
        width: calc(100% - 2rem);

        &.show {
            transform: translateY(-12px);
        }
    }
}