@use '../../../../assets/style' as *;

.custom-input {
    padding: 1rem !important;
    border-radius: $radius-general !important;
    font-size: $font-sm !important;
    border: 1px solid $text-disable !important;
    line-height: 24px !important;

    &::placeholder {
        color: $text-field !important;
    }

    &:focus {
        box-shadow: none !important;
        border-color: $blue-GFDP !important;
        transition: 150ms ease-in-out !important;
    }
}

.custom-check {
    font-size: $font-md !important;
    border-color: $text-disable !important;
    // margin-left: -1.25em !important;
}

.custom-check:checked {
    background-color: $blue-GFDP !important;
    border-color: $blue-GFDP !important;
}