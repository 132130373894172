@use '../../assets/style' as *;

.report {
    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .report-content {
        display: grid;
        place-items: center;
        gap: 40px;
        padding: 48px 72px 64px 72px;

        .header {
            display: grid;
            place-items: center;
            max-width: 800px;
            gap: 16px;
            text-align: center;

            p {
                font-size: $font-lg;
                line-height: 28px;
            }
        }

        .body {
            display: grid;
            place-items: center;
            max-width: 800px;
            gap: 40px;
            text-align: center;
        }

        .pdf {
            background-color: $info-disable;
            border-radius: $radius-general;
            width: 720px;
            overflow: hidden;
            display: grid;
            place-items: center;
            position: relative;
            padding: 16px;

            .doc {
                height: 600px;
            }

            .arrow {
                position: absolute;
                opacity: 0;
                pointer-events: none;
                transition: 300ms ease-in-out;

                path {
                    fill: $grey-GFDP;
                    transition: 150ms ease-in-out;
                }
            }

            .prev {
                left: 40px;
            }

            .next {
                right: 40px;
            }

            &:hover {
                .arrow {
                    opacity: 1;
                    transition: 400ms ease-in-out;
                    pointer-events: initial;
                    cursor: pointer;

                    &:hover {
                        path {
                            fill: $black-GFDP;
                            transition: 150ms ease-in-out;
                        }
                    }
                }
            }
        }

        button {
            width: max-content;
        }
    }
}

.fade-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-up.active {
    opacity: 1;
    transform: translateY(0);
}

@include tablet {
    .report {

        .report-content {
            padding: 40px;

            .pdf {
                width: 100%;
            }
        }
    }

    .fade-up {
        width: 100%;
    }
}

@include mobile {
    .report {

        .report-content {
            padding: 40px 16px;

            .header,
            .body {
                p {
                    font-size: $font-md;
                    line-height: 24px;
                }
            }

            .pdf {
                width: 100%;

                .arrow {
                    display: none;
                }
            }
        }
    }
}

@include mobile_sm {
    .report {
        .report-content {
            gap: 24px;

            .header,
            .body {
                p {
                    font-size: $font-sm;
                }
            }

            .body {
                gap: 24px;
            }

            .pdf {
                .doc {
                    height: 100%;
                    border-radius: $radius-general;
                }
            }
        }
    }
}