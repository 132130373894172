@use '../../assets/style' as *;

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h5 {
        font-size: $font-xxl;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 630px;
        width: 100%;
        padding: 0 40px;
        gap: 16px;
        text-align: center;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    #login-form {
        font-size: $font-sm;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .form-section {
        position: relative;
        text-align: left;
    }

    .form-check {
        padding-left: 1.75em;
    }

    .middle {
        display: flex;
        justify-content: space-between;

        a {
            font-weight: $font-semibold;
            font-size: $font-sm;
        }
    }

    .remember {

        input,
        label {
            cursor: pointer;
        }

    }

    button {
        width: 100%;
    }

    .onboard {
        flex-direction: column;
        align-items: center;

        p {
            font-size: $font-sm;
            line-height: 2;

            a {
                font-size: $font-sm;
                font-weight: $font-semibold;
            }
        }
    }

    .plink {
        display: flex;
        gap: 4px;
    }

    .visibility {
        position: absolute;
        display: grid;
        place-items: center;
        top: calc(50% + 14.5px);
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
            height: 20px;
            width: 20px;
        }

        &.hidden {
            display: none;
        }
    }
}

@include mobile {
    .login {
        padding: 40px 16px;
        justify-content: space-between;

        .form-wrapper {
            padding: 0;
        }
    }
}