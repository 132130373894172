@use '../../../../assets/style' as *;

.scroll-shadow {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    // transition: 300ms ease-in-out;
}

.outer {
    padding: 0 50px;
}

img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.slider-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $bg-white;
    border-radius: $radius-general;
    // height: 100%;
    margin: 0 16px;
    gap: 24px;

    h6 {
        line-height: 28px;
    }

    .feedback {
        display: flex;
        p {
            line-height: 28px;
        }
    }
}

.impact {
    padding: 16px;
    .profile {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .image {
        display: flex;
        flex-shrink: 0;
        border-radius: 50%;
        width: 56px;
        height: 56px;
    }
}

.donor {
    padding: 40px 16px;
    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
    }

    .image {
        display: flex;
        flex-shrink: 0;
        border-radius: 50%;
        width: 80px;
        height: 80px;
    }

    .header {
        display: flex;
        align-items: center;
        gap: 48px;
    }

    .feedback {
        p {
            text-align: center;
        }
    }
}

@include mobile {
    .slider-card {
        margin: 0 8px;
    }
}