@use '../../../assets/style' as *;

.login {
    display: flex;

    .logo {
        path {
            fill: $bg-white;
        }
    }

    .left {
        position: relative;
        background-size: cover;
        background-position: right;
        background-color: $black-GFDP;

        .logo {
            position: absolute;
            left: 3rem;
            top: 3rem;
        }

        .onboard {
            position: absolute;
            bottom: 5rem;
            left: 0;
            background-color: $bg-white;
            width: max-content;
            padding: 1rem 3rem;
            border-radius: 0 50rem 50rem 0;

            p {
                font-size: $font-sm;
                line-height: 2;
                a {
                    font-size: $font-sm;
                    font-weight: $font-semibold;
                }
            }
        }

        .plink {
            display: flex;
            gap: 4px;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .spinner-login {
        background-color: transparent !important;
    }
}