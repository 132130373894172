@mixin four_k {
    @media only screen and (min-width: 2560px) {
        @content;
    }
}

@mixin laptop_lg {
    @media only screen and (max-width: 1440px) {
        @content;
    }
}

@mixin laptop {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 992px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin mobile_sm {
    @media only screen and (max-width: 576px) {
        @content;
    }
}