@use '../../assets/style' as *;

.terms {
    display: flex;
    justify-content: center;
}

.content {
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 40px;

    a {
        display: unset;
    }
}

@include mobile {
    .content {
        padding: 24px 16px;
    }
}