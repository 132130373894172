@use '../../assets/style' as *;

.close,
.overlay {
    padding-left: 5rem !important;
}

.logo {
    display: none;
}

.history {
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;
    background-color: $light-grey-GFDP;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    transition: 300ms;

    &.wider {
        padding: 40px;
    }
}

.wide-wrapper {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: grid;
    gap: 24px;
    position: relative;
}

.header {
    p {
        color: $black-GFDP;
        font-size: $font-sm;
        line-height: 24px;
    }

    h3 {
        line-height: 36px;
    }
}

.body {
    display: grid;
    gap: 16px;
}

@include tablet {
    .history {
        padding: 40px;
    }
}

@include mobile {
    .close {
        padding-left: 0 !important;
    }

    .logo {
        display: flex;
        background-color: $light-grey-GFDP;
        padding: 16px;
    }
    
    .history {
        padding: 16px 16px 80px 16px !important;
    }
}

@include mobile_sm {
    .header {
        p {
            font-size: $font-xs;
        }
        h3 {
            font-size: $font-lg;
            line-height: 24px;
        }
    }
}