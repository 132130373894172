@use '../../../../assets/style' as *;

.sidebar {
    position: fixed;
    width: 25rem;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 40px;
    z-index: 99;
}

.middle {
    flex-grow: 1;
}

.dashboard {
    background-color: $bg-white;
    transition: width 300ms;

    .bottom-nav {
        display: none;
    }

    .dashboard-nav {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .nav-item {
        padding: 10px 0;
    }

    .link-nav {
        display: flex;
        width: max-content;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        font-size: $font-xl;
        line-height: 22px;
        color: $grey-GFDP;

        path {
            fill: $grey-GFDP;
        }

        &.active {
            color: $blue-GFDP;

            path {
                fill: $blue-GFDP;
            }
        }
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu {
            cursor: pointer;
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .card {
        display: flex;
        align-items: center;
        padding: 24px 16px;
        background-color: $light-grey-GFDP;
        border-radius: $radius-general;
        gap: 16px;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 8px;

        .header {
            font-size: $font-sm;
            text-wrap: nowrap;
        }

        .body {
            display: flex;

            h4 {
                flex-grow: 1;
                text-wrap: nowrap;
            }

            .edit {
                flex-shrink: 0;
                cursor: pointer;
            }
        }
    }

    .profile {
        justify-content: space-between;
        gap: 16px;
        cursor: pointer;

        .accent {
            display: flex;
            width: 17px;
            height: 68px;
            background-color: $light-grey-GFDP;
            border-radius: 8px;
        }

        .username {
            font-size: $font-md;
            font-weight: $font-bold;
            color: $text-title;
            flex-grow: 1;
        }

        .avatar {
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }

        &.active {
            background-color: $light-grey-GFDP;
            border-radius: $radius-general;
            transition: 150ms ease-in-out;

            .accent {
                width: 0;
                transition: 150ms ease-in-out;
            }

            .username {
                color: $blue-GFDP;
                transition: 150ms ease-in-out;
            }

            .arrow {
                margin-right: 8px;
                transition: 150ms ease-in-out;
            }

            path {
                fill: $blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .volunteer {
        flex-direction: column;
        align-items: flex-start;

        .header {
            display: flex;
            align-items: center;
            line-height: 24px;
            gap: 6px;

            path {
                fill: $text-body;
            }
        }

        .content {
            width: 100%;
        }

        .vol-icon {
            display: none;
            background-color: $blue-GFDP;
            padding: 8px;
            border-radius: 12px;

            path {
                fill: $bg-white;
            }
        }

        .body {
            align-items: center;
            gap: 8px;
        }

        .small-input {
            border-color: $text-disable;
            border-radius: 12px;

            &:disabled {
                background-color: $bg-white;
            }
        }

        .copy {
            cursor: pointer;

            path {
                fill: $blue-GFDP;
            }

            &:hover,
            &:active,
            &:focus {
                path {
                    fill: $mid-blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }

        .share {
            padding: $button-sm;
            line-height: 28px;
            display: flex;
            align-items: center;
            width: max-content;
        }
    }

    .link-title,
    .username,
    .content,
    .logo {
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transition: opacity 300ms ease-in-out;
    }

    &.close {
        width: 5rem;
        padding: 40px 16px;
        overflow: hidden;
        transition: width 300ms;

        .top {
            justify-content: center;
        }

        .link-nav {
            overflow: hidden;
            width: 100%;
            gap: 0;
            justify-content: center;
        }

        .link-title,
        .username,
        .content,
        .logo {
            text-wrap: nowrap;
            width: 0;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        .accent,
        .arrow {
            display: none;
        }

        .card {
            padding: 0;
            background-color: unset;
            gap: 0;
            justify-content: center;

            .content {
                height: 0;
            }

            svg {
                height: 40px;
                width: 47px;
            }
        }

        .profile {
            padding: 0;
            background-color: unset;
            gap: 0;
            justify-content: center;

            .username {
                flex-grow: 0;
            }
        }

        .active {
            .closed-avatar {
                display: flex;
                border-radius: 50%;
                outline: 2px solid $blue-GFDP;
                outline-offset: 2px;
            }
        }

        .volunteer {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .content {
                height: 0;
            }
        }

        .vol-icon {
            display: flex;
            background-color: $blue-GFDP;
            padding: 8px;
            border-radius: 12px;

            svg {
                height: unset;
                width: unset;
            }
        }
    }
}

.donation {
    background-color: $light-grey-GFDP;

    p {
        font-size: $font-sm;
        line-height: 28px;
    }

    .middle {
        display: flex;
        flex-direction: column;
        gap: 16px;

        a {
            padding: $button-lg;
            background-color: $blue-GFDP;
            border: 1px solid $blue-GFDP;
            border-radius: $radius-button;
            color: $text-white;
            font-size: $font-sm;
            font-weight: $font-bold;
            line-height: 24px;

            &:hover {
                background-color: $mid-blue-GFDP;
                border-color: $mid-blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .bottom {
        a {
            width: max-content;
            font-size: $font-sm;
            font-weight: $font-bold;
            gap: 4px;

            &:hover {
                path {
                    fill: $mid-blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }
}

.copy {
    min-width: 24px;
    flex-shrink: 0;
    position: relative;
}

$checkmark-left-length: 8px;
$checkmark-right-length: 16px;
$checkmark-color: $blue-GFDP;
$checkmark-thickness: 2px;

@keyframes checkmark {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        border-width: 0;
    }

    50% {
        width: 0;
        height: 0;
        opacity: 1;
        border-width: $checkmark-thickness;
    }

    75% {
        width: $checkmark-left-length;
        height: 0;
        opacity: 1;
        border-width: $checkmark-thickness;
    }

    100% {
        width: $checkmark-left-length;
        height: $checkmark-right-length;
        opacity: 1;
        border-width: $checkmark-thickness;
    }
}

.container {
    height: 20px;
    position: relative;
}

.checkmark {
    animation: checkmark 500ms ease-in-out forwards;
    height: $checkmark-right-length;
    width: $checkmark-left-length;
    border-bottom: $checkmark-thickness solid $checkmark-color;
    border-right: $checkmark-thickness solid $checkmark-color;

    transform-origin: left bottom;
    position: absolute;
    bottom: -35%;
    left: 0;
    transform: translateY(-45%);
    transform: rotate(45deg);
}

@include tablet {
    .sidebar {
        padding: 40px;
    }
}

@include mobile {
    .donation {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: max-content !important;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        gap: 0;
    }

    .dashboard {
        &.close {
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: max-content;
            padding: 8px 0;
            box-shadow: 0px -8px 40px 0px rgba(0, 0, 0, 0.20);

            .bottom-nav {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                gap: 8px;

                .bottom-nav-item {
                    flex-basis: 100%;
                }

                .bottom-link-nav {
                    color: $grey-GFDP;
                    font-size: $font-xs;
                    text-align: center;
                    display: flex;
                    flex-direction: column;

                    path {
                        fill: $grey-GFDP;
                    }

                    .icon {
                        height: 24px;
                        width: 24px;

                        img {
                            border-radius: 50%;
                        }
                    }

                    &.active {
                        color: $blue-GFDP;

                        path {
                            fill: $blue-GFDP;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 300px) {
    .dashboard {
        .bottom-link-title {
            display: none;
        }
    }
}