@use '../../../../assets/style' as *;

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    background-color: $bg-white;
    padding: 24px 72px;

    a {
        color: $text-body;

        &:hover {
            color: $blue-GFDP;
            transition: 150ms ease-in-out;
        }

        &:active,
        &:focus {
            color: $blue-GFDP;
        }
    }

    .left,
    .middle,
    .right {
        display: flex;
        align-items: center;
    }

    .link-group {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            gap: 24px;

            li.active {
                display: none;
            }
        }
    }

    .option-group {
        display: flex;
        gap: 16px;
    }

    .user,
    .group-user {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .avatar {
        background-color: $text-disable;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        height: 32px;
        width: 32px;
    }

    .username {
        display: flex;
    }

    .nav-button {
        padding: $button-sm;
        font-size: $font-xs;
    }

    .user-profile {
        a {
            gap: 8px;
        }
    }

    .user-profile.mobile {
        display: none;
    }

    .lang {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .option-language {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
        }

        label {
            cursor: pointer;

            &.bold {
                font-weight: $font-bold;
                color: $text-title;
            }
        }

        span {
            border-left: 2px solid $text-body;
            height: 16px;
        }
    }

    .lang.mobile {
        display: none;
    }

    .right {
        gap: 16px;
    }
}

.menu {
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 300ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .line {
        fill: none;
        transition: stroke-dasharray 300ms, stroke-dashoffset 300ms;
        stroke: $black-GFDP;
        stroke-width: 5.5;
        stroke-linecap: round;
    }

    .bar-top {
        stroke-dasharray: 40 160;
    }

    .bar-middle {
        stroke-dasharray: 40 142;
        transform-origin: 50%;
        transition: transform 300ms;
    }

    .bar-bottom {
        stroke-dasharray: 40 85;
        transform-origin: 50%;
        transition: transform 300ms, stroke-dashoffset 300ms;
    }

    &.active {
        transform: rotate(45deg);

        .bar-top {
            stroke-dashoffset: -64px;
        }

        .bar-middle {
            //stroke-dashoffset: -20px;
            transform: rotate(90deg);
        }

        .bar-bottom {
            stroke-dashoffset: -64px;
        }
    }
}

@include tablet {
    .header {
        padding: 24px 40px;

        .right {
            gap: 8px;
        }

        .link-group {
            position: absolute;
            top: 76px;
            right: 40px;
            background-color: $bg-white;
            max-height: 0;
            overflow: hidden;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            border-radius: $radius-general;

            ul {
                flex-direction: column;
                gap: 0;
                overflow: hidden;

                li {
                    padding: 12px 24px;

                    a {
                        justify-content: flex-end;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $blue-GFDP;
                        color: $text-white;
                        transition: 150ms ease-in-out;

                        a {
                            color: $text-white;
                        }

                        span {
                            border-color: $bg-white;
                        }
                    }

                    &.lang {
                        &.mobile {

                            &:hover,
                            &:active,
                            &:focus {
                                background-color: unset;

                                span {
                                    border-color: $text-body;
                                }
                            }
                        }
                    }
                }
            }
        }

        .link-group.open {
            max-height: 320px;
            transition: 300ms ease-in-out;
        }
    }

    .menu {
        display: flex;
    }
}

@include mobile {
    .header {
        padding: 16px;

        .link-group {
            width: 100%;
            top: 73px;
            right: 0;
            left: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

            ul {
                li {
                    a {
                        justify-content: flex-start;
                    }
                }
            }
        }

        .user-profile {
            display: none;
        }

        .user-profile.mobile {
            display: flex;
            border-top: 2px solid $light-grey-GFDP;

            a {
                width: 100%;
            }
        }

        .lang,
        .login-link {
            display: none;
        }

        .lang.mobile {
            display: flex;
        }

    }
}