@use '../../assets/style' as *;

.oops {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 4px;
    }

    .link {
        color: $blue-GFDP;
        font-weight: $font-semibold;
        margin-top: 24px;
        cursor: pointer;

        &:hover {
            color: $mid-blue-GFDP;
            transition: 150ms ease-in-out;

            path {
                fill: $mid-blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }
}