@use '../../../../assets/style' as *;

.footer {
    background-color: $blue-GFDP;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 72px;
    color: $text-white;
    text-align: center;
    gap: 40px;

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        p {
            max-width: 1000px;
            font-size: $font-lg;
            line-height: 28px;
            color: $text-white;
        }
    }

    .socmed {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;

        a {
            cursor: pointer;
        }
    }

    .line {
        display: flex;
        max-width: 400px;
        width: 100%;
        height: 1px;
        background-color: $bg-white;
        margin: 12px 0;
    }

    .copy {
        font-size: $font-md;
        font-weight: $font-bold;
    }
}

@include tablet {
    .footer {
        padding: 40px;

        .content {
            p {
                font-size: $font-md;
                line-height: 24px;
            }
        }

        .line {
            margin: 0;
        }

        .copy {
            font-size: $font-sm;
        }
    }
}

@include mobile {
    .footer {
        padding: 40px 16px;

        .content {
            p {
                font-size: $font-sm;
                line-height: 24px;
            }
        }

        .line {
            max-width: 200px;
        }

        .copy {
            font-size: $font-xs;
        }
    }
}