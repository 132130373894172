@use '../../../../assets/style' as *;

.card-wrapper {
    display: flex;
    flex-direction: column;
    // max-width: 500px;
    // width: 100%;
    width: 480px;
    background-color: $bg-white;
    border-radius: $radius-general;
    padding: 24px;

    .form-donation-card {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .donation-type {
        position: relative;
        display: flex;
        gap: 16px;
    }

    .donation-check {
        width: 100%;
        display: flex;
    }

    .type {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .button-check {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $button-sm;
        font-size: $font-sm;
        font-weight: $font-bold;
        line-height: 24px;
        border-radius: 80px;
        background-color: $info-disable;
        color: $text-body;
        cursor: pointer;
    }

    .monthly+.button-check {
        &:hover {
            background-color: $blue-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .monthly:checked+.button-check {
        background-color: rgba(0, 181, 255, 0.2);
        color: $dark-blue-GFDP;
        transition: 150ms ease-in-out;

        &:hover {
            background-color: $blue-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .monthly:focus+.button-check {
        background-color: $blue-GFDP;
        color: $text-white;
        transition: 150ms ease-in-out;
    }

    .single+.button-check {
        &:hover {
            background-color: $green-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .single:checked+.button-check {
        background-color: $green-GFDP;
        color: $text-white;
        transition: 150ms ease-in-out;
    }

    .subtitle {
        text-align: center;
        line-height: 28px;
    }

    .donation-amount {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .nominal-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
    }

    .nominal {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .nominal-button-wrapper {
        text-align: center;
        border: 1px solid $text-disable;
        border-radius: $radius-general;
        overflow: hidden;
        box-sizing: border-box;
    }

    .button-nominal {
        display: grid;
        place-items: center;
        height: 4rem;
        width: 100%;
        font-size: $font-sm;
        font-weight: $font-semibold;
        line-height: 24px;
        color: $text-title;
        cursor: pointer;
    }

    .grid-span-2 {
        grid-column: span 2;
    }

    .grid-span-3 {
        grid-column: span 3;
    }

    .btn-monthly {
        &:hover {
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;

            label {
                color: $blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .btn-single {
        &:hover {
            border-color: $green-GFDP;
            transition: 150ms ease-in-out;

            label {
                color: $green-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .btn-monthly.checked {
        border-color: $blue-GFDP;
        transition: 150ms ease-in-out;

        .button-nominal {
            background-color: $blue-GFDP;
            border-color: $blue-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .btn-single.checked {
        border-color: $green-GFDP;
        transition: 150ms ease-in-out;

        .button-nominal {
            background-color: $green-GFDP;
            border-color: $green-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .other-amount {
        label {
            font-size: $font-sm;
            margin-bottom: 4px;
        }
    }

    .input-other::-webkit-inner-spin-button,
    .input-other::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .input-other,
    .custom {
        padding: 1rem;
        border-radius: $radius-general;
        border-color: $text-disable;
    }

    .input-other {
        color: $text-title;
        box-shadow: none !important;
        border-top-right-radius: $radius-general !important;
        border-bottom-right-radius: $radius-general !important;

        &:focus {
            box-shadow: none;
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;
        }
    }

    .custom {
        background-color: $info-disable;
        color: $text-title;
    }

    .terms {

        input,
        label {
            cursor: pointer;
        }

        label {
            font-size: $font-sm;
            line-height: 24px;

            a {
                font-size: $font-sm;
                display: unset;
            }
        }
    }

    .help {
        display: flex;
        justify-content: center;

        a {
            display: flex;
            gap: 4px;
            color: $text-title;
            font-weight: $font-bold;
            line-height: 28px;

            svg {
                height: 24px;
                width: 24px;
            }

            &:hover {
                color: $blue-GFDP;
                transition: 150ms ease-in-out;

                path {
                    fill: $blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }

    .equivalent {
        background-color: $light-grey-GFDP;
        border-radius: $radius-general;
        display: flex;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        padding: 8px;

        .eq-image {
            height: 80px;
            width: 120px;
            border-radius: 8px;
            overflow: hidden;
            flex-shrink: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .eq-description {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .eq-icons {
            display: flex;
            gap: 8px;
            margin-bottom: 2px;
        }

        .eq-text {
            color: $black-GFDP;
            font-size: $font-xs;
            line-height: 20px;
        }
    }
}

.custom-invalid {
    display: none;
    font-size: $font-xs;
    color: $info-negative;

    &.invalid {
        display: flex;
    }
}

@include tablet {
    .card-wrapper {
        // max-width: unset;
        width: 100%;
        box-shadow: 0px 32px 80px rgba(8, 168, 204, 0.2);
        padding: 16px;

        .subtitle {
            font-size: $font-sm;
            line-height: 24px;
        }
    }
}

@include mobile-sm {
    .card-wrapper {
        .equivalent {
            .eq-image {
                height: auto;
                flex-shrink: unset;
            }

            .eq-text {
                line-height: 16px;
            }
        }
    }
}