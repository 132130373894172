@use '../../../../assets/style' as *;

.modal {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.body {
    display: flex;
    flex-direction: column;
}

.footer {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;

    .load {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
    }

    button {
        width: 100%;
        height: 100%;
        padding: $button-sm;
        font-size: $font-xs;
        line-height: 24px;
    }
}

.recurring {
    .option {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 0;
    }

    .nominal-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
    }

    .nominal-button-wrapper {
        text-align: center;
        border: 1px solid $text-disable;
        border-radius: $radius-general;
        overflow: hidden;
        box-sizing: border-box;
    }

    .btn-monthly {
        .button-nominal {
            display: grid;
            place-items: center;
            height: 4rem;
            width: 100%;
            font-size: $font-sm;
            font-weight: $font-semibold;
            line-height: 24px;
            color: $text-title;
            cursor: pointer;
        }

        &:hover {
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;

            label {
                color: $blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }

        &.checked {
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;

            .button-nominal {
                background-color: $blue-GFDP;
                border-color: $blue-GFDP;
                color: $text-white;
                transition: 150ms ease-in-out;
            }
        }
    }

    .nominal {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .other-amount {
        label {
            font-size: $font-sm;
            margin-bottom: 4px;
        }
    }

    .input-group {
        width: 100%;

        label {
            font-size: $font-sm;
            color: $black-GFDP;
        }

        .input-other::-webkit-inner-spin-button,
        .input-other::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-other,
        .custom {
            padding: 1rem;
            border-radius: $radius-general;
            border-color: $text-disable;
        }

        .input-other {
            color: $text-title;
            box-shadow: none !important;
            border-top-right-radius: $radius-general !important;
            border-bottom-right-radius: $radius-general !important;

            &:focus {
                box-shadow: none;
                border-color: $blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }

    }
}

.referral {
    .body {
        gap: 8px;
    }

    .edit {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .link {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
            font-size: $font-sm;
            flex-shrink: 0;
        }

        input {
            line-height: 20px !important;
            padding: 8px 16px !important;
        }
    }

    .warning {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;

        .warning-icon {
            display: flex;

            svg {
                height: 16px;
                width: 16px;
            }
        }

        .text {
            display: flex;
            font-size: $font-sm;
            font-weight: $font-semibold;
            color: $text-title;
            line-height: 14px;
        }
    }
}

.share {
    .body {
        gap: 24px;
    }

    .social {
        display: flex;
        gap: 16px;
    }

    .media {
        $size: 63px;
        height: $size;
        width: $size;
        border: 1px solid $light-grey-GFDP;
        background-color: $light-grey-GFDP;
        border-radius: 50%;

        &:hover {
            border-color: $text-disable;
            background-color: $info-disable;
            transition: 150ms ease-in-out;
        }
    }
}

.success {
    .body {
        gap: 16px;
    }

    .input-group {
        input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            padding: $button-sm !important;
        }

        button {
            padding: $button-sm;
            border-radius: $radius-general;
        }

        .copy {
            min-width: 104.5px;
            display: flex;
            justify-content: center;
        }

        $checkmark-left-length: 8px;
        $checkmark-right-length: 16px;
        $checkmark-color: $bg-white;
        $checkmark-thickness: 2px;

        @keyframes checkmark {
            0% {
                width: 0;
                height: 0;
                opacity: 0;
                border-width: 0;
            }

            50% {
                width: 0;
                height: 0;
                opacity: 1;
                border-width: $checkmark-thickness;
            }

            75% {
                width: $checkmark-left-length;
                height: 0;
                opacity: 1;
                border-width: $checkmark-thickness;
            }

            100% {
                width: $checkmark-left-length;
                height: $checkmark-right-length;
                opacity: 1;
                border-width: $checkmark-thickness;
            }
        }

        .container {
            height: 20px;
            position: relative;
        }

        .checkmark {
            animation: checkmark 500ms ease-in-out forwards;
            height: $checkmark-right-length;
            width: $checkmark-left-length;
            border-bottom: $checkmark-thickness solid $checkmark-color;
            border-right: $checkmark-thickness solid $checkmark-color;

            transform-origin: left bottom;
            position: absolute;
            bottom: -40%;
            left: -8px;
            transform: translateY(-40%);
            transform: rotate(45deg);
        }
    }

    .social {
        display: flex;
        gap: 16px;
    }

    .media {
        $size: 63px;
        height: $size;
        width: $size;
        border: 1px solid $light-grey-GFDP;
        background-color: $light-grey-GFDP;
        border-radius: 50%;

        &:hover {
            border-color: $text-disable;
            background-color: $info-disable;
            transition: 150ms ease-in-out;
        }
    }
}

.password {
    .input-group {
        label {
            font-size: $font-sm;
        }

        position: relative;
        margin-bottom: 8px;
    }

    .visibility {
        position: absolute;
        display: grid;
        place-items: center;
        top: calc(50% + 14.5px);
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
            height: 20px;
            width: 20px;
        }

        &.hidden {
            display: none;
        }
    }
}

.badge {
    gap: 24px;

    .body {
        align-items: center;
        text-align: center;
        gap: 16px;
    }

    .badge-wrapper {
        width: max-content;
        border: 6px solid;
        border-radius: 50%;

        &.yellow {
            border-color: $yellow-GFDP;

            .outline {
                fill: $yellow-GFDP;
            }
        }

        &.light-green {
            border-color: $green-GFDP;

            .outline {
                fill: $green-GFDP;
            }
        }

        &.blue {
            border-color: $blue-GFDP;

            .outline {
                fill: $blue-GFDP;
            }
        }

        &.dark-green {
            border-color: $dark-green-GFDP;

            .outline {
                fill: $dark-green-GFDP;
            }
        }

        &.brown {
            border-color: $brown-GFDP;

            .outline {
                fill: $brown-GFDP;
            }
        }
    }

    .footer {
        justify-content: center;

        button {
            width: max-content;
        }
    }
}

.welcome-dialog {
    position: fixed !important;
    height: max-content !important;
    bottom: 0;
    border-top-right-radius: $radius-general;
}

.report-dialog {
    width: 720px;
    position: relative;
}

.report {
    display: grid;
    gap: 24px;
    padding: 40px;

    .header {
        // display: grid;
        // place-items: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;

        .title {
            font-size: $font-4xl;
            line-height: 40px;
        }
    }

    .report-form {
        display: grid;
        gap: 24px;
    }

    .input-group {
        width: 100%;

        label {
            font-size: $font-sm;
            color: $black-GFDP;
        }

        .input-other::-webkit-inner-spin-button,
        .input-other::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-other {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        .custom {
            padding: 1rem;
            border-radius: $radius-general;
            border-color: $text-disable;
            background-color: $info-disable;
            color: $text-title;
            font-size: $font-sm;
        }
    }

    button {
        width: max-content;
        place-self: center;
    }

    .agree {

        .checkInput {
            cursor: pointer;
        }

        label {
            cursor: pointer;
            font-size: $font-sm;
        }
    }
}

.toast {
    position: fixed;
    bottom: 64px;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: $radius-general;
    padding: 10px 20px 10px 16px;
    width: max-content;
    transform: translateY(124px);
    text-align: left;
    z-index: 1;
    opacity: 0;

    p {
        font-size: $font-xs;
        color: $text-title;
        line-height: 18px;
    }

    &.success {
        background-color: #c7eccc;

        .toast-icon {
            path {
                fill: $dark-green-GFDP;
            }
        }

        .message {

            h6,
            p {
                color: $dark-green-GFDP
            }
        }
    }

    &.error {
        background-color: #fed2d2;

        .toast-icon {
            path {
                fill: #cb0606;
            }
        }

        .message {

            h6,
            p {
                color: #cb0606;
            }
        }
    }

    &.show {
        opacity: 1;
        transform: translateY(32px);
        transition: transform 300ms ease-in-out;
    }
}

@include mobile {
    .modal {
        padding: 16px;
    }

    .welcome {
        padding: 24px;
    }

    .toast {
        width: calc(100% - 1rem);

        &.show {
            transform: translateY(40px);
        }
    }
}