@use '../../assets/style' as *;

.close,
.overlay {
    padding-left: 5rem !important;
}

.logo {
    display: none;
}

.badges {
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;
    background-color: $light-grey-GFDP;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    transition: 300ms;

    &.wider {
        padding: 40px;
    }
}

.wide-wrapper {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: grid;
    gap: 24px;
    position: relative;
}

.header {
    p {
        color: $black-GFDP;
        font-size: $font-sm;
        line-height: 24px;
    }

    h3 {
        line-height: 36px;
    }
}

.body {
    display: grid;
    gap: 16px;
}


.achievement {
    padding: 16px;
    background-color: $bg-white;
    border-radius: $radius-general;

    h6 {
        line-height: 28px;
    }

    p {
        font-size: $font-sm;
        color: $text-title;
        line-height: 24px;
    }
}

.badges-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    .badge {
        background-color: rgba(166, 166, 166, 0.50);
        border-radius: $radius-general;
        position: relative;
        display: grid;
        text-align: center;
        padding: 16px;
        gap: 8px;

        h6 {
            color: $text-white;
            line-height: 32px;
            z-index: 1;
        }

        .desc {
            color: $text-white;
            font-size: $font-sm;
            line-height: 28px;
            flex-grow: 1;
            z-index: 1;
        }

        .point {
            font-size: $font-xxl;
            font-weight: $font-bold;
            color: $text-white;
            z-index: 1;
        }

        .accent {
            position: absolute;

            &.accent-1,
            &.accent-2,
            &.accent-4,
            &.accent-5 {
                right: 0;
                bottom: 0;
            }

            &.accent-3 {
                left: 0;
                top: 0;
            }

            &.locked {
                path {
                    fill: $grey-GFDP;
                    fill-opacity: 0.5;
                }
            }
        }

        &.unlocked {
            &.bg-1 {
                background-color: $yellow-GFDP;

                h6,
                .desc,
                .point {
                    color: $text-title;
                }
            }

            &.bg-2 {
                background-color: $green-GFDP;

                h6,
                .desc,
                .point {
                    color: $text-title;
                }
            }

            &.bg-3 {
                background-color: $blue-GFDP;
            }

            &.bg-4 {
                background-color: $dark-green-GFDP;
            }

            &.bg-5 {
                background-color: $brown-GFDP;
            }
        }
    }
}

@media (min-width: 1000px) and (max-width: 1140px) {
    .badges-wrapper {
        grid-template-columns: 1fr;
    }
}

@include tablet {
    .badges {
        padding: 40px;
    }
}

@include mobile {
    .close {
        padding-left: 0 !important;
    }

    .logo {
        display: flex;
        background-color: $light-grey-GFDP;
        padding: 16px;
    }

    .badges {
        padding: 16px 16px 80px 16px !important;
    }

    .badges-wrapper {
        grid-template-columns: 1fr;
    }
}

@include mobile_sm {
    .header {
        p {
            font-size: $font-xs;
        }

        h3 {
            font-size: $font-lg;
            line-height: 24px;
        }
    }
}