@use '../../assets/style' as *;

.close {
    padding-left: 5rem !important;
}

.logo {
    display: none;
}

.overlay {
    padding-left: 5rem !important;
}

.profile {
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;
    background-color: $light-grey-GFDP;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    position: relative;
    transition: 300ms;

    &.wider {
        padding: 40px;
    }
}

.wide-wrapper {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: grid;
    gap: 24px;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    p {
        color: $black-GFDP;
        font-size: $font-sm;
        line-height: 24px;
    }

    h3 {
        line-height: 36px;
    }

    a {
        gap: 6px;
        font-size: $font-sm;
        font-weight: $font-bold;
        text-wrap: nowrap;

        span {
            display: flex;
            align-items: center;
        }

        svg {
            margin-top: -2px;
            transform: rotate(180deg);
        }

        .logout {
            path {
                fill: $blue-GFDP;
            }
        }

        &:hover {
            path {
                fill: $mid-blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }
}

.body {
    display: grid;
    gap: 16px;
}

.form-wrapper {
    display: grid;
    background-color: $bg-white;
    border-radius: $radius-general;
    padding: 24px;
    gap: 16px;
}

.form-profile {
    display: grid;
    gap: 24px;

    // input:-webkit-autofill { 
    //     -webkit-background-clip: text !important;
    // }

    .avatar {
        display: flex;
        justify-content: center;

        img {
            border-radius: 50%;
            width: 15%;
        }
    }

    .form-section {
        display: grid;
        gap: 16px;
    }

    .input-section {
        display: flex;
        gap: 16px;
    }

    .input-group {
        position: relative;
        width: 100%;

        label {
            font-size: $font-sm;
            color: $black-GFDP;
        }

        .input-other::-webkit-inner-spin-button,
        .input-other::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-other {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;

            &.stand-alone {
                border-radius: $radius-general !important;
            }
        }

        .custom {
            padding: 1rem;
            border-radius: $radius-general;
            border-color: $text-disable;
            background-color: $info-disable;
            color: $text-title;
            font-size: $font-sm;
        }
    }

    .note {
        font-size: $font-xs;
        width: 100%;
        flex-shrink: 0;
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    .custom-select {
        padding: 1rem !important;
        border-radius: $radius-general !important;
        font-size: $font-sm !important;
        border: 1px solid $text-disable !important;
        line-height: 24px !important;

        &:not(:disabled) {
            cursor: pointer;
        }

        &::placeholder {
            color: $text-field !important;
        }

        &:focus {
            box-shadow: none !important;
            border-color: $blue-GFDP !important;
            transition: 150ms ease-in-out !important;
        }
    }
}

.toast {
    position: fixed;
    bottom: 64px;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: $radius-general;
    padding: 10px 20px 10px 16px;
    width: max-content;
    transform: translateY(124px);


    p {
        font-size: $font-xs;
        color: $text-title;
        line-height: 18px;
    }

    &.success {
        background-color: #c7eccc;

        .toast-icon {
            path {
                fill: $dark-green-GFDP;
            }
        }

        .message {

            h6,
            p {
                color: $dark-green-GFDP
            }
        }
    }

    &.error {
        background-color: #fed2d2;

        .toast-icon {
            path {
                fill: #cb0606;
            }
        }

        .message {

            h6,
            p {
                color: #cb0606;
            }
        }
    }

    &.show {
        transform: translateY(0);
        transition: transform 300ms ease-in-out;
    }
}

.visibility {
    position: absolute;
    display: grid;
    place-items: center;
    top: calc(50% + 3.5px);
    right: 16px;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
        height: 20px;
        width: 20px;
    }

    &.hidden {
        display: none;
    }
}

@include tablet {
    .profile {
        padding: 40px;
    }
}

@include mobile {
    .close {
        padding-left: 0 !important;
    }

    .logo {
        display: flex;
        background-color: $light-grey-GFDP;
        padding: 16px;
    }

    .profile {
        padding: 16px 16px 80px 16px !important;
    }

    .form-wrapper {
        padding: 16px;
    }

    .form-profile {
        .avatar {
            img {
                width: 20%;
            }
        }

        .input-section {
            flex-direction: column;
        }

        .button-group {
            display: flex;
            justify-content: unset;
            gap: 8px;

            button {
                width: 100%;
            }
        }
    }

    .toast {
        width: calc(100% - 2rem);

        &.show {
            transform: translateY(-12px);
        }
    }
}

@include mobile_sm {
    .header {
        p {
            font-size: $font-xs;
        }

        h3 {
            font-size: $font-lg;
            line-height: 24px;
        }
    }
}