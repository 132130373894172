@use '../../assets/style' as *;

.donation {
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.logo {
    display: none;
}

.navigation {
    width: max-content;
    font-size: $font-sm;
    font-weight: $font-bold;
    gap: 4px;

    &:hover {
        path {
            fill: $mid-blue-GFDP;
            transition: 150ms ease-in-out;
        }
    }

    &.single,
    &.bottom {
        display: none;
    }
}

.single,
.boost {
    .navigation {
        &.top {
            display: none;
        }
    }
}

.monthly {
    .donation-form {
        .number {
            background-color: $blue-GFDP;
        }

        .input-group {
            input {
                &:checked {
                    background-color: $blue-GFDP;
                    border-color: $blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }
}

.single,
.boost {
    .donation-form {
        .number {
            background-color: $green-GFDP;
        }

        .input-group {
            input {
                &:checked {
                    background-color: $green-GFDP;
                    border-color: $green-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }
}

.donation-form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .form-section {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .header {
        display: flex;
        align-items: center;
        gap: 20px;

        .number {
            flex-shrink: 0;
            height: 44px;
            width: 44px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-white;
            font-size: $font-xl;
            font-weight: $font-bold;
            line-height: 24px;
        }

        .title {
            flex-shrink: 0;
        }

        .line {
            width: 100%;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .nominal-wrapper {
        position: relative;
        display: flex;
        gap: 8px;
    }

    .nominal {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .nominal-button-wrapper {
        width: 100%;
        text-align: center;
        border: 1px solid $text-disable;
        border-radius: $radius-general;
        overflow: hidden;
        box-sizing: border-box;
    }

    .button-nominal {
        display: grid;
        place-items: center;
        height: 4rem;
        width: 100%;
        font-size: $font-sm;
        font-weight: $font-semibold;
        line-height: 24px;
        color: $text-title;
        cursor: pointer;
    }

    .btn-monthly {
        &:hover {
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;

            label {
                color: $blue-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .btn-single {
        &:hover {
            border-color: $green-GFDP;
            transition: 150ms ease-in-out;

            label {
                color: $green-GFDP;
                transition: 150ms ease-in-out;
            }
        }
    }

    .btn-monthly.checked {
        border-color: $blue-GFDP;
        transition: 150ms ease-in-out;

        .button-nominal {
            background-color: $blue-GFDP;
            border-color: $blue-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .btn-single.checked {
        border-color: $green-GFDP;
        transition: 150ms ease-in-out;

        .button-nominal {
            background-color: $green-GFDP;
            border-color: $green-GFDP;
            color: $text-white;
            transition: 150ms ease-in-out;
        }
    }

    .other-amount {
        label {
            font-size: $font-sm;
            margin-bottom: 4px;
        }
    }

    .input-other::-webkit-inner-spin-button,
    .input-other::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .input-other,
    .custom {
        padding: 1rem;
        border-radius: $radius-general;
        border-color: $text-disable;
    }

    .input-other {
        color: $text-title;
        box-shadow: none !important;
        border-top-right-radius: $radius-general !important;
        border-bottom-right-radius: $radius-general !important;

        &:focus {
            box-shadow: none;
            border-color: $blue-GFDP;
            transition: 150ms ease-in-out;
        }
    }

    .subtitle {
        text-align: center;
        line-height: 28px;
    }

    .input-section {
        display: flex;
        gap: 16px;
    }

    .input-group {
        width: 100%;

        label {
            font-size: $font-sm;
            color: $black-GFDP;
        }

        .input-other {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

    }

    .custom {
        padding: 1rem;
        border-radius: $radius-general;
        border-color: $text-disable;
        background-color: $info-disable;
        color: $text-title;
        font-size: $font-sm;
    }

    .accordion-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        .input-group {
            width: calc(100% / 3 - 11px);
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            input {
                margin: 0;
                flex-shrink: 0;
                border-color: $text-disable;
                cursor: pointer;

                &:focus {
                    box-shadow: none;
                }
            }

            label {
                flex-shrink: 0;
                cursor: pointer;
            }
        }
    }

    .pm-icon {
        height: 30px;
        object-fit: contain;
    }

    .note {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $text-disable;
    }

    .donate {
        width: max-content;
        align-self: flex-end;
    }

    .custom-invalid {
        display: none;
        font-size: $font-xs;
        color: $info-negative;

        &.invalid {
            display: flex;
        }
    }
}

.phone {
    font-size: $font-xs;
    width: 100%;
    flex-shrink: 0;
}

@include tablet {
    .donation {
        padding: 40px;
    }

    .donation-form {
        .nominal-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        .grid-span-2 {
            grid-column: span 2;
        }

        .grid-span-3 {
            grid-column: span 3;
        }

        .subtitle {
            font-size: $font-sm;
            line-height: 24px;
        }

        .input-section {
            flex-direction: column;
        }
    }
}

@include mobile {
    .donation {
        padding: 32px 16px;
        gap: 24px;
    }

    .logo {
        display: flex;
        justify-content: center;
    }

    .navigation {

        &.top,
        &.bottom,
        &.single {
            display: flex;
            align-self: center;
            width: max-content;
        }
    }

    .donation-form {
        gap: 24px;

        .donate {
            width: unset;
            align-self: unset;
        }

        .accordion-content {
            .input-group {
                width: calc(100% / 2 - 8px);
            }
        }

        .pm-icon {
            height: 24px;
        }
    }
}