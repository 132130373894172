@use '../../assets/style' as *;

.signup {
    display: flex;
    flex-direction: column;
    align-items: center;

    .volunteer-form {
        // border: 1px solid $black-GFDP;
        max-width: 640px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 40px 0;
    }

    .form-section {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .input-section {
        display: flex;
        gap: 16px;
    }

    .input-group {
        width: 100%;

        label {
            font-size: $font-sm;
            color: $black-GFDP;
        }

        .input-other::-webkit-inner-spin-button,
        .input-other::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .input-other {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        .custom {
            padding: 1rem;
            border-radius: $radius-general;
            border-color: $text-disable;
            background-color: $info-disable;
            color: $text-title;
            font-size: $font-sm;
        }
    }

    .custom-select {
        padding: 1rem !important;
        border-radius: $radius-general !important;
        font-size: $font-sm !important;
        border: 1px solid $text-disable !important;
        line-height: 24px !important;
        cursor: pointer;

        &::placeholder {
            color: $text-field !important;
        }

        &:focus {
            box-shadow: none !important;
            border-color: $blue-GFDP !important;
            transition: 150ms ease-in-out !important;
        }
    }

    .check {
        input,
        label {
            cursor: pointer;
        }

        input {
            border-radius: 50%;
        }
    }

    .note {
        font-size: $font-xs;
        width: 100%;
        flex-shrink: 0;
    }

    .button-group {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    .button-secondary {
        border: 1px solid $text-field;
        padding: $button-md;
        border-radius: $radius-button;
        background-color: $bg-white;
        color: $black-GFDP;
        font-size: $font-sm;
        font-weight: $font-bold;
        line-height: 24px;

        &:hover {
            background-color: $info-disable;
            transition: 150ms ease-in-out;
        }
    }
}

@include mobile {
    .signup {
        padding-left: 16px;
        padding-right: 16px;
        .input-section {
            flex-direction: column;
        }
        .button-group {
            display: flex;
            justify-content: unset;
            gap: 8px;
            button {
                width: 100%;
            }
        }
    }
}