@use '../../assets/style' as *;

.campaign {
    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .hero-wrapper {
        display: flex;
        justify-content: space-between;
        min-height: 650px;
        background-size: cover;
        background-position: top;
        position: relative;

        svg {
            height: 100%;
            width: 100%;
        }

        .accent-hero-lg {
            position: absolute;
            bottom: -2px;
            left: 0;

            path {
                fill: $light-grey-GFDP;
            }
        }

        .inner-wrapper {
            display: flex;
            width: 100%;
            gap: 100px;
            padding: 64px 72px;
            max-width: 1540px;
        }

        .left,
        .right {
            display: flex;
            align-items: center;
            // min-height: 500px;
            width: calc(100% / 2);
            // border: 1px solid $bg-black;
        }

        .left {
            position: relative;

            .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px
            }

            .accent-hero-sm {
                display: none;
            }

            h1 {
                color: $text-white;
                font-size: $font-5xl;
                line-height: 56px;
            }

            p {
                color: $text-white;
                font-size: $font-3xl;
                line-height: 36px;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .campaign-body {
        background-color: $light-grey-GFDP;
        display: flex;
        justify-content: center;
    }

    .campaign-content {
        position: relative;
        padding: 64px 72px;
        max-width: 1540px;

        .image {
            float: right;
            max-width: 40rem;
            max-height: 40rem;
            object-fit: cover;
            object-position: center center;
            margin-left: 24px;
            margin-bottom: 24px;
            border-radius: $radius-general;
        }

        .article {
            color: $black-GFDP;
            font-size: $font-xl;
            line-height: 36px;

            .first-letter {
                float: left;
                font-size: 140px;
                color: $blue-GFDP;
                height: 90px;
                line-height: 106px;
                margin-right: 8px;
                margin-left: -10px;
            }
        }
    }

    .campaign-help {
        display: flex;
        justify-content: center;
    }

    .help-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 1000px;
        width: 100%;
        padding: 64px 72px;
        gap: 40px;

        a {
            display: unset;
            font-size: $font-lg;
        }

        p {
            font-size: $font-lg;
            line-height: 36px;
        }

        .check-icon {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            background-color: $blue-GFDP;
            width: 48px;
            height: 48px;
            padding: 8px;
            border-radius: $radius-general;
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .help-list {
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: 720px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .help {
                display: flex;
                align-items: center;
                text-align: left;
                gap: 24px;
                font-size: $font-xl;
            }
        }
    }

    .bg-contacts {
        background-color: $bg-light-blue;
        display: flex;
        justify-content: center;
    }
}

.paragraph {
    display: block;
    margin-bottom: 16px;

    &:last-child {
        margin: 0;
    }
}

.fade-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-up.active {
    opacity: 1;
    transform: translateY(0);
}

@include tablet {
    .campaign {
        .hero-wrapper {
            background-image: none !important;
            min-height: unset;

            .accent-hero-lg {
                display: none;
            }

            .left,
            .right {
                width: 100%;
            }

            .left {
                background-size: cover;
                border-radius: $radius-general;
                padding: 70px;
                
                .title-wrapper {
                    gap: 16px;
                }

                .accent-hero-sm {
                    display: block;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    top: 50%;

                    path {
                        fill: $light-grey-GFDP;
                    }
                }

                h1 {
                    font-size: $font-4xl;
                    line-height: 40px;
                }

                p {
                    font-size: $font-lg;
                    line-height: 28px;
                }
            }

            .right {
                .custom {
                    box-shadow: none;
                }
            }

            .inner-wrapper {
                flex-direction: column;
                padding: 0 40px;
                gap: 24px;
                background: linear-gradient(rgba(244, 244, 244, 0), rgba(244, 244, 244, 1));
            }
        }

        .campaign-content {
            padding: 40px;
            display: flex;
            flex-direction: column-reverse;
            gap: 32px;

            .image {
                float: unset;
                max-width: unset;
                max-height: 480px;
                margin: 0;
                width: 100%;
            }

            .article {
                font-size: $font-md;
                line-height: 30px;

                .first-letter {
                    height: 100px;
                    line-height: 120px;
                }
            }
        }

        .help-wrapper {
            padding: 40px;
            a {
                font-size: $font-md;
            }
            p {
                font-size: $font-md;
                line-height: 30px;
            }

            .help-list {
                .help {
                    font-size: $font-lg;
                }
            }
        }
    }

    .fade-up {
        width: 100%;
    }
}

@include mobile {
    .campaign {
        .hero-wrapper {
            .left {
                justify-content: center;
                padding: 40px 16px;
                text-align: center;

                h1 {
                    font-size: $font-xxl;
                    line-height: 28px;
                }

                p {
                    font-size: $font-sm;
                    line-height: 20px;
                }
            }

            .inner-wrapper {
                padding: 0 16px;
            }
        }

        .campaign-content {
            padding: 40px 16px;

            .image {
                max-height: 320px;
            }

            .article {
                .first-letter {
                    font-size: 120px;
                    height: 90px;
                    line-height: 86px;
                    margin-right: 6px;
                }
            }
        }

        .help-wrapper {
            padding: 40px 16px;
            gap: 32px;
            a {
                font-size: $font-md;
            }
            p {
                font-size: $font-md;
                line-height: 30px;
            }

            .check-icon {
                width: 42px;
                height: 42px;
            }

            .help-list {
                gap: 16px;
                .help {
                    font-size: $font-lg;
                    gap: 16px;
                }
            }
        }
    }
}