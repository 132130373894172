@use '../../../../assets/style' as *;

.primary {
    border: 1px solid $blue-GFDP;
    padding: $button-md;
    background-color: $blue-GFDP;
    border-radius: $radius-button;
    color: $text-white;
    font-family: $font-family;
    font-size: $font-sm;
    font-weight: $font-bold;
    line-height: 24px;

    &:hover {
        background-color: $mid-blue-GFDP;
        border-color: $mid-blue-GFDP;
        transition: 150ms ease-in-out;
    }

    &:focus {
        outline: 2px solid $blue-GFDP;
        outline-offset: 2px;
    }
}

.secondary {
    border: 1px solid $text-field;
    padding: $button-md;
    background-color: $bg-white;
    border-radius: $radius-button;
    color: $text-title;
    font-family: $font-family;
    font-size: $font-sm;
    font-weight: $font-bold;
    line-height: 24px;

    &:hover {
        background-color: $info-disable;
        transition: 150ms ease-in-out;
    }
}

.green {
    border: 1px solid $green-GFDP;
    padding: $button-md;
    background-color: $green-GFDP;
    border-radius: $radius-button;
    color: $text-white;
    font-family: $font-family;
    font-size: $font-sm;
    font-weight: $font-bold;
    line-height: 24px;

    &:hover {
        background-color: $mid-green-GFDP;
        border-color: $mid-green-GFDP;
        transition: 150ms ease-in-out;
    }
}

.transparent {
    border: 1px solid $text-white;
    padding: $button-md;
    background-color: transparent;
    border-radius: $radius-button;
    color: $text-white;
    font-family: $font-family;
    font-size: $font-sm;
    font-weight: $font-bold;
    line-height: 24px;

    &:hover {
        background-color: $bg-white;
        color: $text-title;
        transition: 150ms ease-in-out;
    }
}

.grey {
    border: 1px solid $info-disable;
    padding: $button-md;
    background-color: $info-disable;
    border-radius: $radius-button;
    color: $text-body;
    font-family: $font-family;
    font-size: $font-sm;
    font-weight: $font-bold;
    line-height: 24px;

    &:hover {
        border-color: $text-field;
        color: $text-title;
        transition: 150ms ease-in-out;
    }
}

.back-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 99;
    height: 40px;
    width: 40px;
    background-color: $blue-GFDP;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;

    &:hover {
        background-color: $mid-blue-GFDP;
        transition: 150ms ease-in-out;
    }
}

.back-to-top.active {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
    transition: 300ms ease-in-out;
}