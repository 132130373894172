@use '../../assets/style' as *;

.close,
.overlay {
    padding-left: 5rem !important;
}

.logo {
    display: none;
}

.donor {
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    min-height: 100dvh;
    background-color: $light-grey-GFDP;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    transition: 300ms;

    &.wider {
        padding: 40px;
    }
}

.wide-wrapper {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    display: grid;
    gap: 24px;
    position: relative;
}

.header {
    p {
        color: $black-GFDP;
        font-size: $font-sm;
        line-height: 24px;
    }

    h3 {
        line-height: 36px;
    }
}

.body {
    display: grid;
    gap: 16px;
}

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;

    .grid-span-2 {
        grid-column: span 2;
    }

    .grid-span-3 {
        grid-column: span 3;
    }

    .grid-span-4 {
        grid-column: span 4;
    }

    .box {
        position: relative;
        border-radius: $radius-general;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;


        &:nth-child(-n+2) {
            background-color: $bg-white;
        }

        &:nth-last-child(-n+2) {
            background-color: $black-GFDP;
            overflow: hidden;

            h6 {
                color: $text-white;
                line-height: 28px;
            }

            p {
                color: $text-white;
                font-size: $font-sm;
                line-height: 28px;
            }

            button {
                padding: $button-sm;
                font-size: $font-xs;
            }

            .link-button {
                z-index: 1;
                background-color: $blue-GFDP;
                color: $text-white;
                padding: $button-sm;
                font-size: $font-xs;
                font-weight: $font-bold;
                border-radius: $radius-button;
                border: 1px solid $blue-GFDP;
                line-height: 20px;
                cursor: pointer;

                &:hover {
                    border-color: $mid-blue-GFDP;
                    background-color: $mid-blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }

    .box1 {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            flex-shrink: 0;
        }

        .user {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .bio {
            display: flex;
            flex-direction: column;
            gap: 6px;

            p {
                font-size: $font-xs;
                line-height: 20px;
                color: $text-title;
            }
        }

        .summary {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 2px solid $blue-GFDP;
            padding: 8px;
            overflow: hidden;
            border-radius: $radius-general;

            h2 {
                line-height: 40px;
                color: $blue-GFDP;
            }

            p {
                line-height: 28px;
                color: $text-title;
            }
        }
    }

    .box2 {
        .achievement {
            flex-grow: 1;

            h6 {
                line-height: 28px;
            }

            p {
                font-size: $font-sm;
                color: $text-title;
                line-height: 24px;
            }
        }

        .display {
            display: flex;
            align-items: center;
            gap: 32px;
        }

        .badges {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: relative;
            flex-grow: 20;

            .badge {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
            }

            .badge-title,
            .point {
                font-size: $font-xs;
                line-height: 20px;
                color: $grey-GFDP;
                font-weight: $font-normal;
                text-align: center;

                &.unlocked {
                    font-weight: $font-bold;
                    color: $text-title;
                }
            }

            .bullet {
                background-color: $grey-GFDP;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                z-index: 1;

                &.unlocked {
                    background-color: $mid-blue-GFDP;
                }
            }
        }

        .progress {
            width: 100%;
            height: 6px;
            border-radius: 5px;
            position: absolute;
            bottom: 30.5px;

            &::-webkit-progress-value {
                background-color: $mid-blue-GFDP;
                transition: 500ms ease;
            }

            &::-webkit-progress-bar {
                background-color: $grey-GFDP;
            }
        }

        .view {
            font-size: $font-xs;
            line-height: 14px;
            text-align: center;
            display: flex;
            flex-direction: column;

            path {
                fill: $blue-GFDP;
            }

            &:hover {
                path {
                    fill: $mid-blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }

    .box3 {

        h6,
        button {
            z-index: 1;
        }

        p {
            flex-grow: 1;
            z-index: 1;
        }

        .accent-boost {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 24px;
        }
    }

    .box4 {

        h6,
        p {
            z-index: 1;
        }

        .accent-share {
            position: absolute;
            bottom: -1px;
            right: 0;
            top: 45%;
        }

        .input-group {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
            z-index: 1;

            input {
                flex-grow: 1;
                background: transparent;
                color: $text-disable;
                padding: $button-sm !important;
                font-size: $font-xs !important;
                line-height: 20px !important;
            }

            button {
                display: flex;
                align-items: center;
                background: none;
                box-shadow: none;
                border: none;
                padding: 0;
                font-size: $font-sm;
                gap: 4px;

                .edit-text {
                    color: $text-white;
                    text-wrap: nowrap;
                }

                svg {
                    height: 20px;
                    width: 22px;
                }

                path {
                    fill: $bg-white;
                }
            }
        }

        .button-group {
            display: flex;
            gap: 16px;
            z-index: 1;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                line-height: 20px;

                span {
                    display: flex;
                }

                &:last-child {
                    flex-grow: 1;
                }
            }
        }
    }
}

.card {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    background-color: $bg-white;
    border-radius: $radius-general;
    gap: 16px;

    .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 8px;

        .top {
            font-size: $font-sm;
            text-wrap: nowrap;
        }

        .bottom {
            display: flex;

            h4 {
                flex-grow: 1;
                text-wrap: nowrap;
            }

            .edit {
                flex-shrink: 0;
                cursor: pointer;
            }
        }
    }
}

.spinner-dashboard {
    background-color: $light-grey-GFDP !important;
    z-index: -1 !important;
    width: calc(100% - 25rem) !important;

    &.close {
        width: calc(100% - 5rem) !important;
        padding: 0 !important;
    }
}

.copy {
    min-width: 116px;
}

$checkmark-left-length: 8px;
$checkmark-right-length: 16px;
$checkmark-color: $bg-white;
$checkmark-thickness: 2px;

@keyframes checkmark {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        border-width: 0;
    }

    50% {
        width: 0;
        height: 0;
        opacity: 1;
        border-width: $checkmark-thickness;
    }

    75% {
        width: $checkmark-left-length;
        height: 0;
        opacity: 1;
        border-width: $checkmark-thickness;
    }

    100% {
        width: $checkmark-left-length;
        height: $checkmark-right-length;
        opacity: 1;
        border-width: $checkmark-thickness;
    }
}

.container {
    height: 20px;
    position: relative;
}

.checkmark {
    animation: checkmark 500ms ease-in-out forwards;
    height: $checkmark-right-length;
    width: $checkmark-left-length;
    border-bottom: $checkmark-thickness solid $checkmark-color;
    border-right: $checkmark-thickness solid $checkmark-color;

    transform-origin: left bottom;
    position: absolute;
    bottom: -35%;
    left: -8px;
    transform: translateY(-45%);
    transform: rotate(45deg);
}

@media (min-width: 1201px) and (max-width: 1286px) {
    .grid-wrapper {
        .box4 {
            .button-group {
                flex-direction: column;
            }
        }
    }
}

@include laptop {
    .grid-wrapper {
        grid-template-columns: 1fr;

        .box1 {
            flex-basis: unset;
            flex-direction: row;
            gap: 16px;

            .user {
                width: 40%;
            }

            .summary {
                width: 60%;
                flex-direction: row;

                .wrapper {
                    width: 100%;
                }
            }
        }

        .box2 {

            .achievement {
                flex-grow: 0;
            }
        }

        .box4 {
            .button-group {
                button {
                    width: 100%;

                    &:last-child {
                        flex-grow: unset;
                    }
                }
            }
        }
    }
}

@include tablet {
    .donor {
        padding: 40px;
    }

    .grid-wrapper {

        .box1,
        .box2 {
            padding: 16px;
        }
    }
}

@include mobile {
    .close {
        padding-left: 0 !important;
    }

    .logo {
        display: flex;
        background-color: $light-grey-GFDP;
        padding: 16px;
    }

    .spinner-dashboard {
        &.close {
            width: 100% !important;
        }
    }

    .donor {
        padding: 16px 16px 80px 16px !important;
    }

    .grid-wrapper {

        .box1 {
            flex-direction: column;

            .user {
                width: 100%;
            }

            .summary {
                width: 100%;
            }
        }

        .box2 {
            .display {
                flex-direction: column;
                align-items: unset;
                gap: 24px;
            }

            .view {
                width: max-content;

                svg {
                    display: none;
                }

                display: unset;
            }
        }
    }

    .edit-text {
        display: none;
    }
}

@include mobile_sm {
    .header {
        p {
            font-size: $font-xs;
        }

        h3 {
            font-size: $font-lg;
            line-height: 24px;
        }
    }
}