@use '../../assets/style' as *;

.onboarding {
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .logo {
        display: none;
    }

    .option-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex-grow: 1;

        a {
            display: unset;
        }
    }

    .option {
        padding: 24px;
        // border: 1px solid $blue-GFDP;
        border-radius: $radius-general;
        display: flex;
        align-items: center;
        gap: 24px;
        cursor: pointer;

        .icon,
        .arrow {
            flex-shrink: 0;
        }

        .description {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;

            p {
                font-size: $font-sm;
                line-height: 24px;
            }
        }

        &:hover {
            // box-shadow: 0px 32px 80px 0px rgba(0, 181, 255, 0.20);
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.10);
            transition: 150ms ease-in-out;

            path {
                fill: $mid-blue-GFDP;
                transition: 300ms ease-in-out;
            }
        }
    }

    .navigation {
        display: none;
    }
}

@include tablet {
    .onboarding {
        padding: 40px;
    }
}

@include mobile {
    .onboarding {
        padding: 32px 16px;

        .logo {
            display: flex;
            justify-content: center;
        }

        .navigation {
            display: flex;
            width: max-content;
            align-self: center;
            font-size: $font-sm;
            font-weight: $font-bold;
            gap: 4px;
    
            &:hover {
                path {
                    fill: $mid-blue-GFDP;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }
}

@include mobile_sm {
    .onboarding {
        .option {
            padding: 16px;
        }
    }
}