@use '../../assets/style' as *;

.volunteer {
    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-self: center;
        max-width: 800px;
        gap: 8px;
        text-align: center;

        p {
            font-size: $font-lg;
            line-height: 28px;
        }
    }

    .button-lg {
        padding: $button-lg;
        background-color: $blue-GFDP;
        color: $text-white;
        font-size: $font-sm;
        font-weight: $font-semibold;
        border: 1px solid $blue-GFDP;
        border-radius: $radius-button;

        &:hover {
            background-color: $mid-blue-GFDP;
            border-color: $mid-blue-GFDP;
            transition: 150ms ease-in-out;
        }
    }

    .hero-wrapper {
        display: flex;
        justify-content: center;
        min-height: 650px;
        background-size: cover;
        background-position: top;
        position: relative;

        svg {
            height: 100%;
            width: 100%;
        }

        .accent-hero-lg {
            position: absolute;
            bottom: -2px;
            right: 0;
        }

        .inner-wrapper {
            display: flex;
            width: 100%;
            gap: 100px;
            padding: 64px 72px;
            max-width: 1540px;
        }

        .left,
        .right {
            display: flex;
            align-items: center;
            // min-height: 500px;
            width: calc(100% / 2);
            // border: 1px solid $bg-black;
        }

        .left {
            position: relative;

            .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px
            }

            .accent-hero-sm {
                display: none;
            }

            h1 {
                color: $text-white;
                font-size: $font-5xl;
                line-height: 56px;
            }

            p {
                color: $text-white;
                font-size: $font-3xl;
                line-height: 36px;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .volunteer-body {
        svg {
            height: 100%;
            width: 100%;
        }

        .volunteer-content {
            display: flex;
            background-color: $dark-green-GFDP;
        }

        .left,
        .right {
            display: flex;
            width: calc(100% / 2);
            position: relative;
        }

        .left {
            .image {
                width: 100%;
            }

            .accent-volunteer {
                position: absolute;
                bottom: 0;
                top: 0;
                left: 72px;
            }
        }

        .right {
            flex-direction: column;
            justify-content: center;
            padding: 64px 72px;
            box-sizing: border-box;
            gap: 24px;

            h2,
            h4,
            p {
                color: $text-white;
            }

            h2 {
                line-height: 36px;
            }

            p {
                font-size: $font-md;
                line-height: 28px;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    .volunteer-benefits {
        display: flex;
        justify-content: center;
    }

    .benefits-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 1000px;
        width: 100%;
        padding: 64px 72px;
        gap: 40px;

        .check-icon {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            background-color: $blue-GFDP;
            width: 48px;
            height: 48px;
            padding: 8px;
            border-radius: $radius-general;
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .benefits-list {
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: 720px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .benefits {
                display: flex;
                align-items: center;
                text-align: left;
                gap: 24px;
                font-size: $font-xl;
            }
        }
    }
}

.fade-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-up.active {
    opacity: 1;
    transform: translateY(0);
}

@include tablet {
    .volunteer {
        .hero-wrapper {
            background-image: none !important;
            min-height: unset;

            .accent-hero-lg {
                display: none;
            }

            .left,
            .right {
                width: 100%;
            }

            .left {
                background-size: cover;
                border-radius: $radius-general;
                padding: 70px;

                .title-wrapper {
                    gap: 16px;
                }

                .accent-hero-sm {
                    display: block;
                    position: absolute;
                    bottom: -2px;
                    right: 0;
                    top: 50%;
                }

                h1 {
                    font-size: $font-4xl;
                    line-height: 40px;
                }

                p {
                    font-size: $font-lg;
                    line-height: 28px;
                }
            }

            .inner-wrapper {
                flex-direction: column;
                padding: 0 40px;
                gap: 24px;
            }
        }

        .volunteer-body {
            .volunteer-content {
                flex-direction: column;
            }

            .left,
            .right {
                width: 100%;
            }

            .left {
                max-height: 480px;

                .image {
                    object-position: top;
                }

                .accent-volunteer {
                    left: 40px;
                }
            }

            .right {
                padding: 40px;
            }
        }

        .benefits-wrapper {
            padding: 40px;
            h2 {
                font-size: $font-xxl;
                line-height: 28px;
            }

            .benefits-list {
                .benefits {
                    font-size: $font-lg;
                }
            }
        }
    }

    .fade-up {
        width: 100%;
    }
}

@include mobile {
    .volunteer {
        .header {
            p {
                font-size: $font-md;
                line-height: 24px;
            }
        }

        .hero-wrapper {
            margin-bottom: 24px;
            .left {
                justify-content: center;
                padding: 40px 16px;
                text-align: center;

                h1 {
                    font-size: $font-xxl;
                    line-height: 28px;
                }

                p {
                    font-size: $font-sm;
                    line-height: 20px;
                }
            }

            .inner-wrapper {
                padding: 0 16px;
            }
        }

        .volunteer-body {
            .left {
                max-height: 320px;

                .accent-impacts {
                    left: 16px;
                }
            }

            .right {
                padding: 40px 16px;
                text-align: center;

                p {
                    font-size: $font-sm;
                    line-height: 24px;
                }
            }
        }

        .benefits-wrapper {
            padding: 40px 16px;
            gap: 32px;
            h2 {
                font-size: $font-xl;
            }

            .check-icon {
                width: 42px;
                height: 42px;
            }

            .benefits-list {
                gap: 16px;
                .benefits {
                    font-size: $font-lg;
                    gap: 16px;
                }
            }
        }
    }
}