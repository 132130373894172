@use '../../assets/style' as *;

.landing {
    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-self: center;
        max-width: 800px;
        gap: 8px;
        text-align: center;

        p {
            font-size: $font-lg;
            line-height: 28px;
        }
    }

    .hero-wrapper {
        display: flex;
        justify-content: center;
        min-height: 650px;
        background-size: cover;
        position: relative;

        svg {
            height: 100%;
            width: 100%;
        }

        .accent-hero-lg {
            position: absolute;
            bottom: -2px;
            left: 0;
        }

        .inner-wrapper {
            display: flex;
            width: 100%;
            gap: 100px;
            padding: 64px 72px;
            max-width: 1540px;
        }

        .left,
        .right {
            display: flex;
            align-items: center;
            // min-height: 500px;
            width: calc(100% / 2);
            // border: 1px solid $bg-black;
        }

        .left {
            position: relative;

            .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px
            }

            .accent-hero-sm {
                display: none;
            }

            h1 {
                color: $text-white;
                font-size: $font-5xl;
                line-height: 56px;
            }

            p {
                color: $text-white;
                font-size: $font-3xl;
                line-height: 36px;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .impacts-wrapper {
        svg {
            height: 100%;
            width: 100%;
        }

        .impacts-content {
            display: flex;

            &:nth-of-type(odd) {
                background-color: $brown-GFDP;

                .left {
                    .accent-impacts {
                        position: absolute;
                        bottom: 0;
                        top: 0;
                        left: 72px;
                    }
                }
            }

            &:nth-of-type(even) {
                flex-direction: row-reverse;
                background-color: $green-GFDP;

                .left {
                    .accent-impacts {
                        position: absolute;
                        bottom: 0;
                        top: 0;
                        right: 72px;
                    }
                }
            }
        }

        .left,
        .right {
            display: flex;
            width: calc(100% / 2);
            position: relative;
        }

        .right {
            flex-direction: column;
            justify-content: center;
            padding: 64px 72px;
            box-sizing: border-box;
            gap: 24px;

            h2,
            h4,
            p {
                color: $text-white;
            }

            h2 {
                line-height: 36px;
            }

            p {
                font-size: $font-lg;
                line-height: 28px;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .benchmarks {
                display: flex;
                gap: 24px;
            }

            .box {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 16px;
                border: 2px solid $bg-white;
                border-radius: $radius-general;
                padding: 16px 24px;

                .box-icon {
                    font-size: $font-4xl;
                    flex-shrink: 0;
                }

                .box-desc {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }
    }

    .ten-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 30px;
        padding: 64px 72px;

        .top {
            display: flex;
            justify-content: space-between;
            max-width: 1440px;
            width: 100%;
        }

        .bottom {
            display: flex;
            max-width: 1440px;

            p {
                text-align: center;
                font-size: $font-lg;
                line-height: 28px;
            }
        }

        .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 24px;
            gap: 14px;
            width: calc(100% / 3);

            .title {
                display: flex;
                align-items: center;
                gap: 24px;
            }

            .subtitle {
                text-align: center;
                font-size: $font-lg;
                line-height: 23px;
            }
        }
    }

    .campaigns-wrapper {
        background-color: $black-GFDP;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 64px 72px;

        .header {

            h2,
            p {
                color: $text-white;
            }
        }

        .body {
            display: flex;
            gap: 24px;

            a {
                justify-content: unset;
                align-items: unset;
            }

            .campaign-card {
                border-radius: $radius-general;
                padding: 24px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: 100%;

                &:nth-of-type(odd) {
                    background-color: $yellow-GFDP;

                    .campaign-title,
                    .campaign-subtitle,
                    h6 {
                        color: $black-GFDP;
                    }

                    .campaign-help {
                        border-color: $black-GFDP;

                        .check-icon {
                            border-color: $black-GFDP;

                            path {
                                fill: $black-GFDP;
                            }
                        }
                    }

                    &:hover {
                        background-color: $mid-yellow-GFDP;
                        transition: 150ms ease-in-out;
                    }
                }

                &:nth-of-type(even) {
                    background-color: $blue-GFDP;

                    .campaign-title,
                    .campaign-subtitle,
                    h6 {
                        color: $text-white;
                    }

                    .campaign-help {
                        border-color: $bg-white;

                        .check-icon {
                            border-color: $bg-white;

                            path {
                                fill: $bg-white;
                            }
                        }
                    }

                    &:hover {
                        background-color: $mid-blue-GFDP;
                        transition: 150ms ease-in-out;
                    }
                }

            }

            .campaign-image {
                background-color: $text-disable;
                border-radius: $radius-general;
                display: flex;
                overflow: hidden;
                height: 240px;
                justify-content: center;

                .image {
                    width: 100%;
                    object-position: left;
                }
            }

            .middle,
            .campaign-helplist {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .middle {
                flex-grow: 1;
            }

            .campaign-help {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 8px;
                border: 1px solid;
                border-radius: $radius-general;

                .check-icon {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    flex-shrink: 0;
                    border: 1px solid;
                }
            }
        }
    }

    .testimony-bg,
    .steps-bg {
        background-color: $light-grey-GFDP;
        display: flex;
        justify-content: center;
    }

    .testimony-wrapper,
    .steps-wrapper,
    .mitra-wrapper,
    .faq-wrapper {
        padding: 64px 72px;
        max-width: 1540px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .card-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        .steps {
            background-color: $bg-white;
            border-radius: $radius-general;
            padding: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 8px;

            p {
                font-size: $font-md;
                line-height: 24px;
            }

            &:last-child {
                grid-column: 2 / 3;
            }
        }
    }

    .benefits-bg {
        background-color: $blue-GFDP;
        display: flex;
        justify-content: center;
        position: relative;

        .accent {
            position: absolute;
            right: 0;
            bottom: -1px;
        }
    }

    .benefits-wrapper {
        padding: 64px 72px;
        width: 800px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        z-index: 1;

        h2,
        h6,
        p {
            color: $text-white;
        }

        .box-wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .box {
                background-color: $mid-blue-GFDP;
                border-radius: $radius-general;
                padding: 16px;
                text-align: center;

                h6 {
                    font-size: $font-xl;
                    font-weight: 500;
                    line-height: 28px;
                }
            }
        }
    }

    .faq-wrapper {
        .body {
            display: flex;
            justify-content: center;
        }
    }

    .faq-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        list-style: none;
        padding: 0;
        margin: 0;
        max-width: 800px;
        width: 100%;

        a {
            display: unset;
        }

        h6 {
            font-size: $font-md;
            line-height: 28px;
        }

        p {
            line-height: 28px;
        }

        .faq {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-height: 64px;
            overflow: hidden;
            cursor: pointer;
            transition: 500ms ease-in-out;

            path {
                transition: 300ms ease-in-out;
            }

            .question {
                display: flex;
                gap: 24px;
                align-items: center;
                padding: 16px;
                border: 2px solid $blue-GFDP;
                border-radius: $radius-general;
                background-color: $bg-white;
                transition: 300ms ease-in-out;

                h6 {
                    flex-grow: 1;
                    transition: 300ms ease-in-out;
                }

                .arrow {
                    flex-shrink: 0;
                    transition: 300ms ease-in-out;
                }
            }

            .answer {
                padding: 16px;
                border-radius: $radius-general;
                background-color: rgba(216, 247, 254, 0.3);
            }

            &:hover {
                h6 {
                    color: $blue-GFDP;
                }

                path {
                    fill: $blue-GFDP;
                }
            }

            &.open {
                .question {
                    background-color: $blue-GFDP;

                    h6 {
                        color: $text-white;
                    }

                    .arrow {
                        transform: rotate(180deg);

                        path {
                            fill: $bg-white;
                        }
                    }
                }

                max-height: 400px;
            }
        }


    }

    .bg-contacts {
        background-color: $bg-light-blue;
        display: flex;
        justify-content: center;
    }
}

.fade-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-up.active {
    opacity: 1;
    transform: translateY(0);
}

@include laptop_lg {
    .landing {
        .impacts-wrapper {
            .right {
                .box {
                    justify-content: center;

                    .box-icon {
                        display: none;
                    }

                    .box-desc {
                        text-align: center;
                    }
                }
            }
        }

        
    }
}

@media (max-width: 1650px) {
    .landing {
        .campaigns-wrapper {
            .body {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@include tablet {
    .landing {
        .hero-wrapper {
            background-image: none !important;
            margin-bottom: 24px;
            min-height: unset;

            .accent-hero-lg {
                display: none;
            }

            .left,
            .right {
                width: 100%;
            }

            .left {
                background-size: cover;
                border-radius: $radius-general;
                padding: 70px;

                .title-wrapper {
                    gap: 16px;
                }

                .accent-hero-sm {
                    display: block;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    top: 50%;
                }

                h1 {
                    font-size: $font-4xl;
                    line-height: 40px;
                }

                p {
                    font-size: $font-lg;
                    line-height: 28px;
                }
            }

            .inner-wrapper {
                flex-direction: column;
                padding: 0 40px;
                gap: 24px;
            }
        }

        .impacts-wrapper {
            .impacts-content {
                &:nth-of-type(odd) {
                    flex-direction: column;

                    .left {
                        .accent-impacts {
                            left: 40px;
                        }
                    }
                }

                &:nth-of-type(even) {
                    flex-direction: column;

                    .left {
                        .accent-impacts {
                            right: 40px;
                        }
                    }
                }
            }

            .left,
            .right {
                width: 100%;
            }

            .left {
                max-height: 480px;

                .image {
                    object-position: top;
                }
            }

            .right {
                padding: 40px;

                .content {
                    text-align: center;
                }
            }
        }

        .ten-wrapper {
            padding: 40px;

            .top {
                flex-wrap: wrap;

                .box {
                    width: calc(100% / 2);

                    &:last-child {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
        }

        .campaigns-wrapper {
            padding: 40px;

            .body {
                grid-template-columns: 1fr;
                // flex-wrap: nowrap;
                // flex-direction: column;

                .campaign-card {
                    width: 100%;
                }
            }
        }

        .testimony-wrapper,
        .steps-wrapper,
        .mitra-wrapper,
        .faq-wrapper {
            padding: 40px;
        }

        .card-wrapper {
            grid-template-columns: 1fr;

            .steps {
                width: 100%;
                padding: 16px;
                text-align: left;

                p {
                    font-size: $font-sm;
                    line-height: 20px;
                }

                &:last-child {
                    grid-column: 1;
                }
            }
        }

        .benefits-wrapper {
            padding: 40px;

            .box-wrapper {
                .box {
                    h6 {
                        font-size: $font-lg;
                    }
                }
            }
        }
    }

    .fade-up {
        width: 100%;
    }
}

@include mobile {
    .landing {
        .header {
            p {
                font-size: $font-md;
                line-height: 24px;
            }
        }

        .hero-wrapper {
            .left {
                justify-content: center;
                padding: 40px 16px;
                text-align: center;

                h1 {
                    font-size: $font-xxl;
                    line-height: 28px;
                }

                p {
                    font-size: $font-sm;
                    line-height: 20px;
                }
            }

            .inner-wrapper {
                padding: 0 16px;
            }
        }

        .impacts-wrapper {
            .impacts-content {
                &:nth-of-type(odd) {
                    .left {
                        .accent-impacts {
                            left: 16px;
                        }
                    }
                }

                &:nth-of-type(even) {
                    .left {
                        .accent-impacts {
                            right: 16px;
                        }
                    }
                }

                .right {
                    p {
                        font-size: $font-md;
                        line-height: 24px;
                    }
                }
            }

            .left {
                max-height: 320px;
            }

            .right {
                padding: 40px 16px;
            }
        }

        .ten-wrapper {
            padding: 40px 16px;

            .top {
                flex-wrap: nowrap;
                flex-direction: column;
                gap: 16px;

                .box {
                    width: 100%;

                    &:last-child {
                        margin-top: 0;
                    }

                    .subtitle {
                        font-size: $font-md;
                    }
                }
            }

            .bottom {
                p {
                    font-size: $font-md;
                    line-height: 24px;
                }
            }
        }

        .campaigns-wrapper {
            padding: 40px 16px;
            gap: 24px;

            .body {
                .campaign-card {
                    padding: 16px;

                    p {
                        font-size: $font-sm;
                        line-height: 24px;
                    }
                }
            }
        }

        .testimony-wrapper,
        .steps-wrapper,
        .mitra-wrapper,
        .faq-wrapper {
            padding: 40px 16px;
        }

        .card-wrapper {
            .steps {
                text-align: center;
            }
        }

        .benefits-wrapper {
            padding: 40px 16px;
            width: 100%;
            gap: 32px;

            .box-wrapper {
                gap: 24px;

                .box {
                    h6 {
                        font-size: $font-md;
                        line-height: 24px;
                    }
                }
            }
        }

        .faq-list {
            p {
                line-height: 24px;
            }

            .faq {
                &:hover {
                    h6 {
                        color: $text-title;
                    }

                    path {
                        fill: $text-body;
                    }
                }
            }
        }
    }
}

@include mobile_sm {
    .landing {
        .impacts-wrapper {
            .right {
                .benchmarks {
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }

        .faq-list {

            h6 {
                line-height: 22px;
            }

            .question {
                min-height: 80px;
            }

            .faq {
                max-height: 80px;
            }
        }
    }
}