@use './assets/style' as *;
@use 'bootstrap/scss/bootstrap.scss';
@use 'slick-carousel/slick/slick.scss';
@use 'slick-carousel/slick/slick-theme.scss';

body {
    font-family: $font-family;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: $text-title;
    font-weight: $font-bold;
}

p {
    margin: 0;
    color: $text-body;
}

a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    color: $blue-GFDP;
    font-size: $font-md;
    text-decoration: none;
}

a:hover {
    color: $mid-blue-GFDP;
    transition: 150ms ease-in-out;
}

label {
    color: $text-body;
}

.base-gap {
    padding-top: 90px;
}

.side-gap {
    padding-left: 25rem;
    transition: 300ms;
}

.wide-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.vph {
    height: 100vh;
    height: 100svh;
    height: 100dvh;
}

.custom-input {
    padding: 1rem !important;
    border-radius: $radius-general !important;
    font-size: $font-sm !important;
    border-color: $text-disable !important;
    line-height: 24px !important;

    &::placeholder {
        color: $text-field !important;
    }

    &:focus {
        box-shadow: none !important;
        border-color: $blue-GFDP !important;
        transition: 150ms ease-in-out !important;
    }
}

.form-control.is-invalid,
.custom-input.is-invalid,
.form-check-input.is-invalid {
    border-color: $info-negative !important;
    background-position: right 1rem center !important;

    &:focus {
        border-color: $info-negative !important;
    }
}

.form-check-input.is-invalid~.form-check-label {
    color: $black-GFDP;
}

.invalid-feedback {
    color: $info-negative;
    font-size: $font-xs;
}

.alert {
    font-size: $font-sm;
    border-radius: $radius-general;
    gap: 16px;
    margin: 0;
    text-align: left;

    a {
        display: unset;
        font-size: $font-sm;
    }
}

.alert-danger {
    color: #842029;
}

.temp-disabled {
    pointer-events: none;
}

.fade-up {
    animation: fade-up 1.5s ease-in-out;
}

.spinner-wrapper {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    height: 100svh;
    height: 100dvh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2);
}

.loading-spinner {
    width: 2rem;
    height: 2rem;
    border: 4px solid $blue-GFDP;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin .75s infinite linear;
}

.slick-list {
    display: flex;
}

.slick-track {
    display: flex;
    align-items: stretch;
}

.slick-initialized .slick-slide {
    display: flex;
    // align-self: stretch;
}

.slick-slide {
    align-items: center;
    justify-content: center;
}

.slick-prev:before,
.slick-next:before {
    content: '';
    opacity: unset;
    line-height: unset;
    color: unset;
}

.slick-prev,
.slick-next {
    width: unset;
    height: unset;
    top: calc(50% - 25px);

    &:hover {
        path {
            fill: $blue-GFDP;
            transition: 150ms ease-in-out;
        }
    }
}

.slick-slider {
    padding-bottom: 50px;
}

.slick-slider.selector-landing {
    padding-bottom: 0;
}

.slick-dots li button:before {
    content: '';
    width: unset;
    height: unset;
    line-height: unset;
    color: unset;
    display: none;
}

.slick-dots li.slick-active button:before {
    opacity: unset;
    color: unset;
}

.slick-dots {
    bottom: 0;
    display: flex !important;
    justify-content: center;
    gap: 16px;
}

.slick-dots li {
    width: unset;
    height: unset;
    display: inline-flex;
    margin: 0;
}

.slick-dots li button {
    display: unset;
    padding: 0;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background-color: $text-disable;
    margin: 0;
}

.slick-dots li.slick-active button {
    background-color: $blue-GFDP;
    transition: 150ms ease-in-out;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .accordion-item {
        border: none;

        button {
            border: 1px solid $text-disable !important;
            border-radius: $radius-general !important;
            color: $black-GFDP;
            line-height: 24px;
            padding: 16px;
        }

        .accordion-body {
            padding: 16px;
        }
    }

    .accordion-button {
        &:not(.collapsed) {
            background-color: unset;
            box-shadow: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

.display-badge-icon {
    width: 50px;
    height: 50px;

    .outline {
        fill: $grey-GFDP;
    }

    .stroke {
        stroke: $grey-GFDP;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    &.unlocked {
        .outline {
            fill: $green-GFDP;
        }

        .stroke {
            stroke: $green-GFDP;
        }
    }
}

.page-badge-icon {
    z-index: 1;

    .outline {
        fill: $grey-GFDP;
        fill-opacity: 0.5;
    }

    &.unlocked {
        &.yellow {
            .outline {
                fill: $yellow-GFDP;
                fill-opacity: 1;
            }
        }

        &.light-green {
            .outline {
                fill: $green-GFDP;
                fill-opacity: 1;
            }
        }

        &.blue {
            .outline {
                fill: $blue-GFDP;
                fill-opacity: 1;
            }
        }

        &.dark-green {
            .outline {
                fill: $dark-green-GFDP;
                fill-opacity: 1;
            }
        }

        &.brown {
            .outline {
                fill: $brown-GFDP;
                fill-opacity: 1;
            }
        }

    }
}

.modal-content {
    border: none;
    border-radius: $radius-general;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);

    .modal-title {
        font-weight: $font-bold;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        border: none;
        padding: 0;
    }

    button {
        margin: 0;
    }

    .modal-footer {
        flex-wrap: nowrap;
    }

    .yellow {
        .outline {
            fill: $yellow-GFDP;
        }
    }

    .light-green {
        .outline {
            fill: $green-GFDP;
        }
    }

    .blue {
        .outline {
            fill: $blue-GFDP;
        }
    }

    .dark-green {
        .outline {
            fill: $dark-green-GFDP;
        }
    }

    .brown {
        .outline {
            fill: $brown-GFDP;
        }
    }
}

.modal-fullscreen {
    .modal-content {
        border-top-right-radius: $radius-general;
        border-top-left-radius: $radius-general;
    }
}

.custom-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 16px;
    margin: 0;
}

.subtitle-list {
    list-style: lower-alpha;
    padding-left: 20px;
}

canvas {
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.10);
}

canvas,
.textLayer,
.annotationLayer {
    width: 100% !important;
    height: 100% !important;
}

@keyframes fade-up {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@include tablet {
    .wide-wrapper {
        width: 100%;
    }

    .slick-slider.selector-landing {
        padding-bottom: 40px;
    }


}

@include mobile {
    .base-gap {
        padding-top: 74px;
    }

    .side-gap {
        padding-left: 0;
    }

    .slick-arrow {
        display: none;
    }

    .slick-dots li button {
        width: 12px;
        height: 12px;
    }
}

@include mobile_sm {

    canvas,
    .textLayer,
    .annotationLayer {
        border-radius: $radius-general;
    }
}

@include four_k {
    .wide-wrapper {
        max-width: 1440px;
        width: 100%;
        margin: auto;
    }
}